import integerValue from '../../../helpers/number_helper';
import fuzzySearch from '../store/fuzzy_search';

class InputExtractor {
  call(instructions) {
    const parsedInstructions = instructions
      .map((instruction) => this.#parseAcceptableInput(instruction))
      .filter((instruction) => instruction !== null);
    return this.#summarize(parsedInstructions);
  }

  // private

  #parseAcceptableInput(instruction) {
    const { value, unusedWords } = integerValue(instruction);

    if (typeof value === 'number') {
      return this.#evaluateNumberAndWords(value, unusedWords);
    }

    const wordsAndNumbers = instruction
      .toLowerCase()
      .split(' ')
      .map((word) => integerValue(word).value || word);

    const numberIndex = wordsAndNumbers.findIndex((word) =>
      Number.isInteger(word),
    );
    const firstNumber =
      numberIndex !== -1 ? wordsAndNumbers.splice(numberIndex, 1)[0] : null;
    const restOfWords = wordsAndNumbers.join(' ');
    return this.#evaluateNumberAndWords(firstNumber, restOfWords);
  }

  #evaluateNumberAndWords(number, restOfWords) {
    if (typeof number !== 'number') {
      return null; // Null
    }

    const words = restOfWords.split(' ');
    words.unshift(restOfWords);

    for (const word of words) {
      const result = fuzzySearch.call(word);
      // processSingleInstruction differentiates by type (number/string)
      if (result) {
        return [number, result.item.labelText];
      }
    }

    return number; // Number
  }

  #summarize(parsedInstructions) {
    let output = [];
    let prevItem = 0;
    let currentValue = null;

    for (const currentItem of parsedInstructions) {
      let total = 0;

      if (Number.isInteger(currentItem)) {
        total = prevItem + currentItem;
        currentValue = total;
        if (prevItem === 0) {
          output.push(currentValue);
        } else {
          output[output.length - 1] = currentValue;
        }
        prevItem = total;
      } else if (Array.isArray(currentItem)) {
        const [number, label] = currentItem;
        total = prevItem + number;
        if (prevItem === 0) {
          output.push(`${total} ${label}`);
        } else {
          output[output.length - 1] = `${total} ${label}`;
        }
        prevItem = 0;
      }
    }
    return output;
  }
}

export default InputExtractor;
