import BaseMapController from './base_map_controller';
import utility from '../helpers/utility';
import { drawMarker } from './helpers/od_maps_helper';

export default class extends BaseMapController {
  async connect() {
    await super.connect();
    const { markers } = this.element.dataset;
    this.markersData = JSON.parse(markers);
    this.drawMarkers();
  }

  drawMarkers() {
    this.markersData.forEach((markerData) => {
      utility.untilAvailable("google.maps.LatLng");
      drawMarker(markerData, this.map, 'od-marker', this.markers);
    });
  }
}
