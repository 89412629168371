import DeepgramSocketSpeechRecognition from '../vads/deepgram_socket_speech_recognition';
import Logger from '../../../helpers/logger';
import Store from '../../../store/store';
import WebApiSpeechRecognition from '../vads/web_api_speech_recognition';
import userSettings from './user_settings';
import utility from '../../../helpers/utility';

class SpeechRecognition extends Store {
  start() {
    this.increment('speech_controller_counter');
    return this.#initOnce();
  }

  stop() {
    if (this.useDeepgram()) {
      return;
    }

    if (this.recognition) {
      if (this.recognition !== 'INITIALIZING') {
        this.recognition.stop();
      }
      this.recognition = null;
      Logger.warn(`[SPEECH RECOGNITION TERMINATED]`);
      return true;
    }
    return false;
  }

  resume(eventCaller) {
    try {
      if (this.#useBrowserVAD()) {
        this.recognition.resume(eventCaller);
      }
    } catch (error) {
      Logger.warn(`[${eventCaller}] Error: ${error.message}`);
    }
  }

  resumeIfSpeechControllerExists(eventCaller) {
    if (this.get('speech_controller_counter') === undefined) {
      return;
    }

    this.resume(eventCaller);
  }

  toggleState() {
    if (userSettings.toggleAllowVoice()) {
      Logger.warn(`[SPEECH RECOGNITION TOGGLE: ON]`);
      this.#recognitionStart();
      this.#setIntervalForAutoSleepWhenUserInactive();
    } else {
      Logger.warn(`[SPEECH RECOGNITION TOGGLE: OFF]`);
      this.recognition.stop();
    }
  }

  useDeepgram() {
    return document.querySelector('head').dataset.useDeepgramSpeechRecognition === 'true';
  }

  // private

  #initOnce() {
    if (this.recognition) {
      return false;
    }

    this.recognition = 'INITIALIZING';

    this.#init()
      .then((result) => {
        this.recognition = result;

        if (userSettings.allowVoice()) {
          this.#setIntervalForAutoSleepWhenUserInactive();
        } else {
          if (this.#useBrowserVAD()) {
            this.recognition.stop();
          }
          Logger.warn('[SPEECH RECOGNITION IS OFF]');
        }
      });

    return true;
  }

  async #init() {
    if (this.#useBrowserVAD()) {
      return Promise.resolve((new WebApiSpeechRecognition()).init(this));
    }

    return this.#deepgramInstance();
  }

  async #deepgramInstance() {
    if (!userSettings.allowVoice()) {
      return;
    }

    if (!MediaRecorder.isTypeSupported('audio/webm')) {
      Logger.warn('[ABORT] MediaRecorder does not support type audio/webm');
      return;
    }

    this.stream = await navigator.mediaDevices.getUserMedia({
      audio: {
        channelCount: 1,
        echoCancellation: true,
        autoGainControl: true,
        noiseSuppression: true,
      },
    });

    return await (new (DeepgramSocketSpeechRecognition)).init(this);
  }

  #useBrowserVAD() {
    return !this.useDeepgram();
  }

  async #recognitionStart() {
    while (!this.recognition) {
      await utility.sleep();
    }
    try {
      this.recognition.start();
    } catch (error) {
      if (error.name == 'InvalidStateError') {
        Logger.warn({ error });
      } else {
        throw error;
      }
    }
  }

  #setIntervalForAutoSleepWhenUserInactive() {
    if (this.#toggleListeningLink() === null) {
      return;
    }
    const seconds = this.#doFastTest() ? 10 : 3000;
    this.autoSleepInterval = setInterval(this.#autoSleepWhenUserInactive.bind(this), seconds);
    userSettings.initLatestTranscriptionTimestamp();
  }

  #autoSleepWhenUserInactive() {
    if (!userSettings.allowVoice()) {
      return;
    }

    if (userSettings.activelySpeaking()) {
      return;
    }

    clearInterval(this.autoSleepInterval);
    this.#toggleListeningLink().click();
    Logger.warn(`[SPEECH RECOGNITION SLEEP] No user activity`);
  }

  #toggleListeningLink() {
    if (!this.toggleListeningLink) {
      this.toggleListeningLink = document.getElementById('toggle-listening-command');
    }
    return this.toggleListeningLink;
  }

  #doFastTest() {
    return window.location.href.endsWith('/confirm_pick/edit?no_user_activity_test=1');
  }
}

const speechRecognition = new SpeechRecognition();

export default speechRecognition;
