/*
 * This loads the Google Maps API
 * config: has the API key
 */
import Logger from '../../helpers/logger';

export function loadGoogleMapsApiAsync(config) {
  if (window.google) {
    return;
  }

  try {
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${config.key}`;
    script.async = true;
    script.defer = true;
    document.head.appendChild(script);
  } catch (error) {
    Logger.warn(error);
  }
}

export default {
  loadGoogleMapsApiAsync,
};
