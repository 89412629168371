import { Controller } from '@hotwired/stimulus';
import utility from '../helpers/utility';

export default class extends Controller {
  static targets = ['output'];

  static values = {
    odId: String
  };
  
  check() {
    this.revealResultHtml();
  }

  async requestAmendmendableStatus() {
    const url = `/v1/amendable_check/${this.odIdValue}`;
    const response = await fetch(url);
    
    if (!response.ok) {
      throw new Error(`Error status: ${response.status}`);
    }

    return await response.json();
  }

  amendmentStatusHtml(data) {
    return `<span>${data.amendable ? "<i class='fa fa-check'></i>" : "<i class='fa fa-times'></i>"}</span> <span>${data.message}</span>`
  }

  async revealResultHtml() {
    const originalHtml = this.outputTarget.innerHTML;
    
    try {
      const data = await this.requestAmendmendableStatus();
      this.outputTarget.innerHTML = this.amendmentStatusHtml(data) ;
    } catch (error) {
      this.outputTarget.innerHTML = `Error: ${error.message}`;
    }

    await utility.sleep(2000)

    this.outputTarget.innerHTML = originalHtml;
  }
}
