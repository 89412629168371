// base_map_controller.js

import Logger from '../helpers/logger';
import utility from '../helpers/utility';
import { Controller } from '@hotwired/stimulus';
import { getIconPath } from './helpers/od_maps_helper';
import { loadGoogleMapsApiAsync } from './helpers/map_utils';

export default class extends Controller {
  static targets = ['map'];

  async connect() {
    this.markers = [];
    this.map = null;
    this.infoWindow = null;
    const { apiKey } = this.element.dataset;
    try {
      loadGoogleMapsApiAsync({ key: apiKey });
      await utility.untilAvailable("google.maps.Map");
      this.init();
    } catch (error) {
      Logger.warn('Failed to load Google Maps API', error);
    }
  }

  init() {
    this.initializeMap();
    this.initializeInfoWindow();
  }

  initializeMap() {
    this.map = new google.maps.Map(this.mapTarget, {
      center: { lat: 51.509865, lng: -0.118092 },
      zoom: 10,
    });
  }

  initializeInfoWindow() {
    this.infoWindow = new google.maps.InfoWindow({
      content: '',
      disableAutoPan: true,
    });
  }

  loadInitialMapData(markers) {
    if (markers.length === 0) {
      return;
    }
    if (!this.hasSetInitialCenter) {
      this.setInitialMapCenter(markers[0]);
    }
    markers.forEach((marker) => {
      this.addMarker(marker);
    });
  }

  setInitialMapCenter(record) {
    const position = new google.maps.LatLng(record.lat, record.lng);
    this.map.setCenter(position);
  }

  addMarker(record) {
    const position = new google.maps.LatLng(record.lat, record.lng);

    const marker = new google.maps.Marker({
      position,
      map: this.map,
      id: record.id,
      title: record.dom_id,
      infowindow: record.info_window,
      label: {
        text: record.name,
        fontSize: '10px',
        fontWeight: 'bold',
        className: 'map-marker-label',
      },
      icon: getIconPath(record),
    });

    if (marker.infowindow) {
      marker.addListener('click', () => {
        this.openInfoWindow(marker);
      });
    }
    this.markers.push(marker);
  }

  openInfoWindow(marker) {
    this.infoWindow.close();
    this.infoWindow.setContent(marker.infowindow);
    this.infoWindow.open(this.map, marker);
    this.map.panTo(marker.getPosition());
  }

  getUpdatedList(record) {
    this.getData('update_list', { reference_marker_id: record.id });
  }

  /*
    This method is called when the server sends an update_list message.
    It will update the marker if it exists, otherwise it will delete it.
    @param markers: the list of markers that should be on the map. This is raw Data and not of type Marker.
    @param record: the marker that was updated
  */
  updateMarker(markers, record) {
    if (markers.length === 0) {
      this.deleteMarker(record);
      return;
    }
    const marker = markers.find((m) => m.id === record.id);
    // If the marker doesn't exist in the updated list, delete it.
    if (!marker) {
      this.deleteMarker(record);
      return;
    }

    // Find the existing marker and update it.
    // If the marker doesn't exist, add it.
    const existingMarker = this.findMarker(marker.id);
    if (existingMarker) {
      existingMarker.setTitle(record.dom_id);
      const position = new google.maps.LatLng(record.lat, record.lng);
      existingMarker.setPosition(position);
      existingMarker.info_window = record.info_window;
      existingMarker.icon = getIconPath(record);
    } else {
      this.addMarker(record);
    }
  }

  deleteMarker(record) {
    const marker = this.findMarker(record.id);
    if (marker) {
      marker.setMap(null);
      this.markers = this.markers.filter((m) => m !== marker);
    }
  }

  findMarker(id) {
    return this.markers.find((m) => m.id === id);
  }

  disconnect() {
    if (this.subscription === undefined) return;

    this.subscription.unsubscribe();
  }
}
