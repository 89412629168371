const untilAvailable = async (name) => {
  while (!isLoaded(name)) {
    await sleep();
  }
}

const sleep = (msec = 10) => {
  return new Promise(resolve => setTimeout(resolve, msec));
}

// private

const isLoaded = (name) => {
  let result = window;
  for (const item of name.split(".")) {
    try {
      result = result[item];

      if (result === undefined) {
        return false;
      }
    } catch (e) {
      return false;
    }
  }
  return true;
}

export default {
  sleep,
  untilAvailable,
}
