import Store from '../../../store/store';

class UserSettings extends Store {
  constructor() {
    super();
  }

  allowVoice(value = null) {
    if (value !== null) {
      this.set('allow_voice', value);
    }

    return this.get('allow_voice');
  }

  toggleAllowVoice() {
    this.set('allow_voice', !this.get('allow_voice'));

    return this.get('allow_voice');
  }

  voiceSpeed(value = null, defaultSpeed = 1) {
    if (value !== null) {
      this.set('voice_speed', value);
    }

    return this.get('voice_speed') || defaultSpeed;
  }

  initLatestTranscriptionTimestamp() {
    this.setLatestTranscriptionTimestamp();
  }

  setLatestTranscriptionTimestamp() {
    this.set('latest_transcription_timestamp', Date.now());
  }

  activelySpeaking() {
    const secondsElapsed = (Date.now() - this.get('latest_transcription_timestamp')) / 1000;
    return secondsElapsed <= this.#inactivityLimitSeconds();
  }

  // private

  #inactivityLimitSeconds() {
    if (window.location.href.endsWith('/confirm_pick/edit?no_user_activity_test=1')) {
      return 0.1; // For testing in CI
    }

    return 300; // 5m in seconds
  }
}

const userSettings = new UserSettings();
export default userSettings;
